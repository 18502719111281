import React, { useEffect } from 'react';
import { withOptimizely, WithOptimizelyProps } from '@optimizely/react-sdk';
import { RouteComponentProps } from '@reach/router';
import { Mixpanel } from 'mixpanel-browser';

import { NewConfirmation } from './NewConfirmation';

import { MixpanelEvents } from '@entities/enums';
import { useAuth } from '@hooks/useAuth';

interface Props extends RouteComponentProps, WithOptimizelyProps {
  mixpanel: Mixpanel;
}

const NewConfirmationPageComponent: React.FC<Props> = ({ mixpanel, optimizely, location }) => {
  const { user } = useAuth();

  const isLoggedIn = !!user?.accountId;

  useEffect(() => {
    mixpanel.track(MixpanelEvents.PAGE_CONFIRMATION, {
      pageName: 'confirmation',
    });

    optimizely?.track(MixpanelEvents.PAGE_CONFIRMATION);
  }, [mixpanel, optimizely]);

  return <NewConfirmation isLoggedIn={isLoggedIn} location={location} />;
};

export const NewConfirmationPage = withOptimizely<Props, null>(NewConfirmationPageComponent);
