import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import {
  Col,
  CTAButton,
  Divider,
  Heading1,
  InfoNotification,
  P,
  Row,
  Stack,
  Strong,
} from '@ovotech/element';
import { WindowLocation } from '@reach/router';
import { useAtomValue, useSetAtom } from 'jotai';

import { getCopy } from './copy';

import { NewBasicPage, StyledCard, TopSummary } from '@components';
import { EXTERNAL_ROUTE_MY_OVO_HOME } from '@constants/routes';
import { defaultStore, homePlanStoreAtom, selectedProductAtom } from '@src/store/store';
import { BlackHeading4 } from '@src/Styles/Common.Styles';
import { LeadMidnight } from '@components/Banners/styled';
import { useQuotesPlatform } from '@hooks/useQuotesPlatform';
import { ProductCode } from '@src/types/Quote';
import { usePromoComponentsNew } from '@hooks/usePromoComponentsNew';
import { useAbsPromoComponentsNew } from '@hooks/useAbsPromoComponentsNew';

interface Props {
  location?: WindowLocation;
  isLoggedIn: boolean;
}

const WhatHappensNextNotification = styled(InfoNotification)`
  & > div {
    border: 2px solid ${(props) => props.theme.core.color.blue.lightest};
    margin: 0;
  }
`;

const StyledRow = styled(Row)`
  justify-content: space-around;
`;

export const NewConfirmation: React.FC<Props> = ({ location, isLoggedIn }) => {
  const { getBasketPromoBadge, getWhatHappensNext, getOrderSummaryTotal } = usePromoComponentsNew();

  const { getOrderSummaryTotal: getAbsOrderSummaryTotal } = useAbsPromoComponentsNew();

  const setHomePlanStore = useSetAtom(homePlanStoreAtom);

  const selectedProduct = useAtomValue(selectedProductAtom);

  const { getQuoteWrapperByProductCode, getQuoteByProductCode } = useQuotesPlatform();

  const quoteWrapper = selectedProduct && getQuoteWrapperByProductCode(selectedProduct);
  const quote = selectedProduct && getQuoteByProductCode(selectedProduct);

  if (!quote || !location) return null;

  const isAbs = quote?.product == ProductCode.ABS;

  const copy = getCopy({ selectedProduct: quote.product });

  return (
    <NewBasicPage pageName="orderSummary" location={location} quote={quote}>
      <StyledRow isNested>
        <Col small={12} medium={8}>
          <TopSummary
            title={
              <div>
                <Heading1>{copy.heading}</Heading1>
                <Heading1>{copy.product}</Heading1>
              </div>
            }
            alignText={'center'}
          />
          <StyledCard data-testid="order-summary">
            <Stack spaceBetween={[6, 8]}>
              <Stack spaceBetween={[4, 6]}>
                <Stack spaceBetween={4} data-testid="order-summary-your-plan">
                  <BlackHeading4>
                    <Strong>{copy.plan}</Strong>
                  </BlackHeading4>
                  <LeadMidnight data-testid="product-name">
                    {quoteWrapper?.product.name}
                    {quote.excess && quote.excess > 0
                      ? ` with £${quote.excess} excess`
                      : ' no excess'}
                  </LeadMidnight>
                </Stack>

                <Divider type={'differentiated'} />

                {!!quote.offer && !isAbs && (
                  <>
                    <Stack spaceBetween={2} data-testid="order-summary-your-offers">
                      <BlackHeading4>{copy.offers}</BlackHeading4>
                      <div key={quote.offer.type}>{getBasketPromoBadge(quote)}</div>
                    </Stack>
                    <Divider type={'differentiated'} />
                  </>
                )}

                <Stack spaceBetween={4}>
                  <BlackHeading4>{copy.paymentMethod}</BlackHeading4>
                  <LeadMidnight>{copy.directDebit}</LeadMidnight>
                </Stack>

                <Divider type={'differentiated'} />

                <Stack spaceBetween={1} data-testid="order-summary-total">
                  {!isAbs && getOrderSummaryTotal(quote)}

                  {isAbs && getAbsOrderSummaryTotal(quote)}

                  {!quote.offer && (
                    <Stack spaceBetween={4}>
                      <BlackHeading4>{copy.total}</BlackHeading4>
                      <Stack spaceBetween={2}>
                        <LeadMidnight data-testid="monthly-price">
                          <Strong>£{quote.price.discount.monthly}</Strong> a month for 12 months
                        </LeadMidnight>
                        <LeadMidnight>
                          <Strong>£{quote.price.discount.full}</Strong> a year.
                        </LeadMidnight>
                      </Stack>
                    </Stack>
                  )}
                </Stack>

                <Divider type={'differentiated'} />

                <WhatHappensNextNotification
                  data-testid="order-summary-what-happens-next"
                  id={'order-summary-what-happens-next'}
                  title={copy.whatHappensNextHeading}
                  nonce={undefined}
                >
                  <Stack spaceBetween={4}>
                    {copy.whatHappensNextContent?.map((val) => (
                      <P key={val}>{val}</P>
                    ))}
                    {getWhatHappensNext(quote)}
                  </Stack>
                </WhatHappensNextNotification>

                {isLoggedIn && (
                  <CTAButton
                    data-testid="my-ovo-link"
                    onClick={() => {
                      setHomePlanStore(defaultStore);
                      if (EXTERNAL_ROUTE_MY_OVO_HOME)
                        navigate(EXTERNAL_ROUTE_MY_OVO_HOME, { replace: true });
                    }}
                  >
                    Go to my account
                  </CTAButton>
                )}
              </Stack>
            </Stack>
          </StyledCard>
        </Col>
      </StyledRow>
    </NewBasicPage>
  );
};
