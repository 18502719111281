import { useCallback } from 'react';

import { AbsPromoComponentFactory } from '@src/factories/quotesPlatform/types';
import { AbsPromoComponents } from '@src/factories/quotesPlatform';
import { IQuote, OfferType } from '@src/types/Quote';

export const useAbsPromoComponentsNew = () => {
  const getPromoComponent = useCallback(
    (offerType?: OfferType): AbsPromoComponentFactory | null | undefined => {
      if (!offerType) {
        return null;
      }
      return AbsPromoComponents.find(
        (AbsPromoComponent) => AbsPromoComponent.promoType === offerType
      );
    },
    []
  );

  const getTopBasket = (quote: IQuote) => {
    if (!quote.offer) {
      return null;
    }
    const promoComponent = getPromoComponent(quote.offer?.type);
    return promoComponent?.getTopBasket?.(quote);
  };

  const getBasketPromoBadge = (quote: IQuote) => {
    const promoComponent = getPromoComponent(quote.offer?.type);
    return promoComponent?.getBasketPromoBadge?.(quote);
  };

  const getHeadingPrice = (quote: IQuote) => {
    const promoComponent = getPromoComponent(quote.offer?.type);
    return promoComponent?.getHeadingPrice?.(quote);
  };

  const getOrderSummaryTotal = (quote: IQuote) => {
    const promoComponent = getPromoComponent(quote.offer?.type);
    return promoComponent?.getOrderSummaryTotal?.(quote);
  };

  const getBanner = (quote: IQuote) => {
    const promoComponent = getPromoComponent(quote.offer?.type);
    return promoComponent?.getBanner?.(quote);
  };

  return {
    getTopBasket,
    getBasketPromoBadge,
    getHeadingPrice,
    getOrderSummaryTotal,
    getBanner,
  };
};
